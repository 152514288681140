@import "_variables";

.text-center {
	text-align: center;
}
.uppercase {
	text-transform: uppercase;
}
.underline {
	text-decoration: underline;
}
.hard-width {
	position: relative;
	width: 100vw;
	margin-left: -50vw;
	margin-right: -50vw;
	left: 50%;
	right: 50%;
}
.w-100 {
	width: 100%;
}
.h-100 {
	height: 100%;
}

.bg {
	&--primary {
		background-color: $color--primary;
	}
	&--secondary {
		background-color: $color--secondary;
	}
	&--grey {
		background-color: $color--grey;
	}
	&--white {
		background-color: #fff;
	}
}

.color {
	&--primary {
		color: $color--primary;
	}
	&--secondary {
		color: $color--secondary;
	}
	&--grey {
		color: $color--grey;
	}
	&--white {
		color: #fff;
	}
}
[disabled] {
	opacity: 0.5;
	cursor: not-allowed;
	pointer-events: none;
}
.icon {
	display: inline-block;
	width: 30px;
}
