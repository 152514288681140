// Resets
.product-cover img {
  box-shadow: none;
  border: 1px solid lighten($color--grey, 30%);
}
.product-images > li.thumb-container > .thumb {
  box-shadow: none;
  border: 1px solid lighten($color--grey, 30%) !important;
  &.selected,
  &:hover {
    border-color: $color--secondary !important;
  }
}
.btn-comment {
  @extend .btn-primary;
  i {
    display: none;
  }
}
.product-manufacturer {
  margin-bottom: 1rem;
}
#product-comments-list-header {
  padding: 0;
  margin-bottom: -1rem;
}
.product-comment-list-item {
  margin: 0;
  padding: 1.5rem 0;
  background: none;
  &:not(:last-child) {
    border-bottom: 1px solid lighten($color--grey, 30%);
  }
  .comment {
    &-infos {
      margin-bottom: 0.25rem;
    }
    &-author {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.3px;
      color: #000;
      margin-right: 5px;
    }
    &-date {
      font-weight: 500;
      font-size: 12px;
    }
    &-content {
      .h2 {
        font-family: $font--primary;
        font-size: 14px;
        margin: 0 0 5px;
      }
      p {
        color: $color--grey;
        margin: 0;
      }
    }
  }
  .grade-stars {
    margin-bottom: 0.75rem;
  }
}
.product-list-reviews {
  left: 0;
}
.bootstrap-touchspin {
  .input-group-btn-vertical {
    .touchspin {
      &-up::after {
        content: "\e145";
      }
      &-down::after {
        content: "\e15b";
      }
    }
  }
}

// End resets

.single {
  &__thumb {
    .product-images {
      .thumb-container {
        img {
          display: inline-block;
        }
      }
    }
  }
  &__content {
    @include min_md {
      padding-left: 1.5rem !important;
    }
    .h1 {
      font-size: 36px;
      font-weight: 800;
      letter-spacing: 0.4px;
      @include max_xs {
        font-size: 30px;
      }
    }

    .product-reference {
      font-size: 16px;
      font-weight: 400;
      color: $color--primary;
    }

    .product-description P {
      font-size: 16px !important;
      font-weight: 300 !important;
    }

    .product-availability {
      margin: 0 0 1.5rem !important;
      span {
        font-weight: 400;
        padding: 6px 15px;
        display: inline-block;
        border: 1px solid currentColor;
        &.success {
          color: #23a21d;
        }
        &.warning {
          color: #d2a811;
        }
        &.danger {
          color: #e26464;
        }
      }
      i {
        display: none;
      }
    }

    .product-variants {
      border-top: 1px solid lighten($color--grey, 30%);
      border-bottom: 1px solid lighten($color--grey, 30%);
      // margin: 1.5rem 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      &-item {
        margin: 0;
        padding: 1.5rem 0;
        select {
          width: 100%;
          box-shadow: none;
        }
      }
    }

    .product-prices {
      display: flex;
      align-items: flex-end;
      gap: 1rem;
      padding: 1.8rem 0;
      border-bottom: 1px solid lighten($color--grey, 30%);
      margin: 0;
      & * {
        margin: 0 !important;
      }
      .product-price {
        font-family: $font--primary;
        font-weight: 800;
        font-size: 40px;
        color: $color--secondary !important;
        line-height: 1;
      }
      .product-discount {
        .regular-price {
          font-size: 18px;
          font-weight: 700;
          color: $color--grey;
        }
      }
    }

    .product-add-to-cart {
      padding: 1.5rem 0;
      align-items: center;
      display: flex;

      .product-quantity {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        gap: 1rem;
        width: max-content;
        max-width: 100%;
        @include max_xs {
          // flex-direction: column;
          width: 100%;
          .input-group-btn-vertical {
            width: 50px;
            .btn {
              i {
                font-weight: 500;
                font-size: 1.5rem;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
              }
            }
          }
        }
        & > * {
          flex: 1;
          margin: 0 !important;
        }
        .qty {
          .input-group {
            float: none;
            box-shadow: none;
            display: flex;
            @include max_xs {
              width: 100%;
              display: flex;
            }
          }
          #quantity_wanted {
            height: 100%;
            text-align: center;
            @include max_xs {
              height: 3.75rem;
              width: 100%;
              font-size: 1.5rem;
            }
          }
          .input-group-btn-vertical {
            height: 100%;
            .btn {
              height: calc(50% + 1px);
            }
          }
        }
        .add {
          .add-to-cart {
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 0.3px;
            padding: 12px 1.5rem;
            height: auto;
            display: flex;
            align-items: center;
            outline: none;
            border-radius: 2px;
            color: $color--secondary;
            background: $color--primary;
            border-color: $color--primary;
            @include max_xs {
              width: 100%;
              justify-content: center;
              padding: 1rem 1.5rem;
            }
            &:hover {
              background: $color--secondary;
              color: $color--primary;
              border-color: $color--primary;
            }
          }
        }
      }

      .control-label {
        padding-right: 17px;
        max-width: max-content;
        @include max_xs {
          display: none;
          // position: relative;
          // top: -46px;
        }
      }
    }
    .qnty-prix {
      @include max_xs {
        display: flex;
        border-top: 1px solid #bcbcbc;
        border-bottom: 1px solid #bcbcbc;
        justify-content: space-between;
      }
    }
    .product-add-to-cart .cart-produit {
      @include max_xs {
        display: none;
      }
    }
    .product-variants,
    .product-prices {
      @include max_xs {
        border-top: none;
        border-bottom: none;
      }
    }
  }

  &__tabs {
    box-shadow: none;
    margin-top: 2rem;
    background: none;
    padding: 0;
    .nav-tabs {
      width: max-content;
      max-width: 100%;
      display: flex;
      border: none;
      margin-bottom: -1px;
      @include max_xs {
        overflow-x: auto;
      }
      .nav-item {
        float: none;
        margin: 0;
        &:not(:first-child) {
          margin-left: -1px;
        }
        .nav-link {
          font-size: 18px;
          font-weight: 400;
          color: $color--grey;
          padding: 1rem 1.5rem;
          border: 1px solid lighten($color--grey, 30%);
          margin: 0;
          @include max_xs {
            width: max-content;
            height: 100%;
          }
          &.active {
            color: $color--primary;
            font-weight: 500;
          }
        }
      }
    }
    .tab-content {
      padding: 2rem 2rem;
      // border: 1px solid lighten($color--grey, 30%);
      @include max_xs {
        padding: 1rem 1rem !important;
      }
      .tab-pane {
        padding-top: 0;
      }
    }
    .description-width {
      padding: 0rem 6rem;
    }
  }

  #description p {
    font-size: 14px;
    text-align: center;
    font-weight: 300;
    line-height: 128, 571% !important;
  }
}

.product-line-actions {
  @include max_xs {
    margin-top: 12px;
  }
}

.product-line-grid-right.qnt-line {
  @include max_xs {
    float: none;
  }
}
.product-line-grid-body {
  @include max_xs {
    margin-bottom: 5px;
  }
}
