/*
 * Custom code goes here.
 * A template should always ship with an empty custom.css
 */

@import "partial/_base";
@import "partial/_components";
@import "_theme-resets";
@import "_header";
@import "_footer";
@import "_product-list";
@import "_category";
@import "_product";

// Homepage
.carousel {
  box-shadow: none;
  margin: 0 auto;
  width: max-content;
  max-width: 100%;
  left: 0;
  padding: 0;
  .carousel-inner {
    height: auto;
    @include max_xs {
      // height: 388px;
      height: 288px;

      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .carousel-item {
      figure,
      a {
        display: block;
        height: 100%;
      }
    }
  }

  .carousel-control {
    &.left {
      background: linear-gradient(to right, rgba(0, 0, 0, 0.15), transparent);
      &:hover {
        background: linear-gradient(to right, rgba(0, 0, 0, 0.45), transparent);
      }
    }
    &.right {
      background: linear-gradient(to left, rgba(0, 0, 0, 0.15), transparent);
      &:hover {
        background: linear-gradient(to left, rgba(0, 0, 0, 0.45), transparent);
      }
    }
  }
  .carousel-indicators {
    margin-bottom: 0;
    bottom: 5px;
    li {
      width: 1rem;
      height: 1rem;
      &.active {
        width: 1rem;
        height: 1rem;
      }
    }
    @include max_sm {
      display: none;
    }
  }
  @include max_sm {
    .direction {
      display: none;
    }
  }
}
.products-section-title {
  @extend .heading;
}

// Packs
.pack {
  @include max_sm {
    padding: 0 !important;
  }
  margin: 2rem 0 0;
  &--inverse {
    margin: 0 0 2rem;
    @include max_sm {
      .pack__inner {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
  &__inner {
    display: flex;
    justify-content: center;
    @include max_sm {
      display: block;
      position: relative;
      width: 100vw;
      margin-left: -50vw;
      margin-right: -50vw;
      left: 50%;
      right: 50%;
    }
    & > * {
      flex: 0 0 50%;
    }
  }
  &__img {
    $offset: 40px;
    position: relative;
    z-index: 2;
    transform: translateY(neg($offset));
    &--inverse {
      transform: translateY($offset);
      position: static;
    }
    @include max_sm {
      transform: none;
    }
    img {
      height: auto !important;
      width: auto !important;
    }
  }
  &__description {
    $offset: 100px;
    $padding: 1rem;
    background: $color--skin;
    color: $color--primary;
    padding: $padding * 2 $padding;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.85rem;
    padding-left: calc(1rem + #{$offset});
    margin-left: neg($offset);

    &--inverse {
      // $offset: 0;
      background-color: $color--primary;
      color: #fff;
      padding-left: $padding;
      padding-right: calc(1rem + #{$offset});
      margin-left: 0;
      margin-right: neg($offset);
      position: relative;
    }
    @include max_sm {
      gap: 0rem;
      margin: 0;
      padding: 3rem 1rem 4rem 1rem;
    }
    small {
      font-size: 24px;
      font-weight: 400;
      text-transform: uppercase;
      color: $color--secondary;
      @include max_xs {
        font-size: 16px;
      }
    }
    .h1 {
      font-size: 44px;
      font-weight: 700;
      letter-spacing: 0;
      color: inherit;
      font-family: "Playfair Display";
      @include max_xs {
        font-size: 30px;
        line-height: 31px;
        margin-bottom: 12px;
      }
    }
    .btn {
      font-size: 16px;
      font-weight: 700;
      padding: 10px 42px;
      line-height: 1.2;
      border: none;
      border-radius: 0;
      @include max_xs {
        width: 100%;
        // padding: 14px 52px;
        padding: 7px 32px;
        max-width: max-content;
        font-size: 12px;
      }
      &:hover,
      &:focus,
      &:active {
        background-color: $color--dark;
      }
    }
  }
}

// Qui sommes nous
.about {
  @include max_xs {
    margin-top: 39px !important;
  }
  .h1 {
    font-size: 43px;
    font-weight: 700;
    margin-bottom: 1.3rem;
    @include max_xs {
      font-size: 36px;
    }
  }
  p {
    color: $color--dark;
    line-height: 1.6;
    font-weight: 300;
    font-family: "Roboto";
    font-size: 18px;
    @include max_xs {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: justify;
    }
  }
}

// Social connect
#follow-teaser,
#fbtwconnect-welcome,
#fbtwconnect-beforeauthpage,
#follow-teaser-footer {
  display: none !important;
}
.auth-page-txt-info-block {
  text-align: center;
  margin-top: 15px;
  font-weight: 400;
  font-size: 14px;
  color: #909090;
}

// Auth
.login-form {
  margin: 0 !important;
}

// Cart
.remove-from-cart {
  color: #f14444 !important;
}

.slick-prev {
  -webkit-mask-image: url(../img/arrow-prev.svg);
  left: -0.5rem;
  transform: translateY(-50%) translateX(-50%);
  @include max_xs {
    left: -13px;
  }
}
.slick-next {
  -webkit-mask-image: url(../img/arrow-next.svg);
  right: -0.5rem;
  transform: translateY(-50%) translateX(50%);
  @include max_xs {
    right: -13px;
  }
}
.search-widget form button[type="submit"] {
  color: #fff;
  right: 0.5rem;
}

#menu-icon {
  cursor: pointer;
}

.icon-remove {
  position: absolute;
  right: 0;
  top: 0;
}

.iconchange .material-icons {
  display: none !important;
}
.icon-close {
  display: none;
}
.iconchange .icon-close {
  display: block;
}
.carousel .direction .carousel-control i {
  background: none;
}
body#checkout .condition-label {
  margin-left: 1.5rem;
}
body#checkout .condition-label label {
  padding-top: 4px;
}

@media (max-width: 767px) {
  .ets_crosssell_tab_content button.slick-arrow {
    top: 31% !important;
  }
  .js-address-selector .address-alias {
    float: inline-end;
    transform: translateY(-18px);
    padding-left: 17px;
  }
}

@media (max-width: 992px) {
  .page-home .featured-products {
    margin-top: -22px !important;
  }

  #footer {
    padding-top: 1.4rem;
  }
  .reassurance {
    padding-top: 29px !important;
    padding-bottom: 33px !important;
  }
  .usesameaddress {
    font-size: 12px;
  }
  .login-form .btn {
    width: 100%;
  }
  #checkout-personal-information-step ul {
    display: flex;
  }
  body#checkout section.checkout-step .forgot-password {
  width: 100%;
  margin-left: 0;
  text-align: right;
  margin-bottom: 12px;
  }
}

body#checkout {
  min-height: 100dvh;
  position: relative;
}
.row.delivery-option .col-sm-1 {
  margin-bottom: 4px;
}
body#checkout #wrapper {
  min-height: 100vh;
}
