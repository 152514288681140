// product list
#products,
.products {
  &[data-carousel] {
    // display: block;
  }
  // padding: 10px 0;
  gap: 1rem;
  // .product-accessories &,
  .product-list-row &,
  .category & {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
    gap: 2rem 1rem;
    margin: 0;
    @include max_sm {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
    &::after {
      display: none;
    }
  }
  .product-miniature {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
  }
  .thumbnail-container {
    width: 100%;
    height: auto;
    margin: 0;
    box-shadow: none;
    &:hover .product-description:after {
      display: none;
    }
    .product-thumbnail {
      height: auto;
      img {
        position: static;
      }
    }
    .product-description {
      position: relative;
      text-align: center;
      width: 100%;
      height: auto;
      padding: 1rem 0.5rem;
      .product-title {
        margin: 0.75rem 0 1rem;
        @include line_clamp(1);
        a {
          font-family: $font--primary;
          font-size: 16px;
          font-weight: 700;
          color: $color--primary;
          text-transform: uppercase;
        }
      }
    }
    .product-price-and-shipping {
      gap: 5px;
      .price {
        font-size: 24px;
        font-weight: 700;
        color: $color--secondary;
      }
      .regular-price {
        font-size: 14px;
        font-weight: 600;
        color: $color--grey;
      }
    }
    .product-list-reviews {
      backdrop-filter: blur(4px);
      background: rgba(255, 255, 255, 0.5);
    }
  }

  @media (max-width: 992px) {
    .slick-arrow {
      top: 30% !important;
    }
  }
}

.product-item {
  &:hover {
    box-shadow: $shadow;
  }
  &__category {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.4px;
  }
  &__description {
    margin: 10px 0 0;
    @include line_clamp(2);
    p {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.3;

      font-family: "Roboto", sans-serif;
    }
  }
  &__footer {
    .btn {
      @include max_sm {
        padding-left: 1rem;
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        gap: 5px;
        .icon {
          position: relative;
          left: unset;
          top: unset;
          transform: unset;
        }
      }
      &:hover {
        background: $color--primary;
        color: #fff;
        border-color: $color--primary;
      }
    }
  }
}

[data-carousel] {
  position: relative;
  @include max_xs {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .slick-track {
    display: flex;
    // gap: 1rem;
    /* margin: 0 -1rem; */
    padding: 1rem 0rem;
    /* overflow: visible;*/
    margin-left: 0;
    margin-right: 0;
    &::before,
    &::after {
      content: "";
    }
  }

  .slick-arrow {
    z-index: 5;
    width: 80px;
    height: 80px;
    // background: lighten($color--grey, 15%);
    background: #e8eaee !important;
    text-indent: -9999px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.7;
    &:focus,
    &:hover {
      outline: none;
      opacity: 1;
      background: $color--grey;
    }
    &.slick-prev {
      -webkit-mask-image: url(../img/arrow-prev.svg);
      left: -0.5rem;
      transform: translateY(-50%) translateX(-50%);
      @include max_xs {
        left: -13px;
      }
    }
    &.slick-next {
      -webkit-mask-image: url(../img/arrow-next.svg);
      right: -0.5rem;
      transform: translateY(-50%) translateX(50%);
      @include max_xs {
        right: -13px;
      }
    }
  }
  .product-item {
    // margin: 1rem;
    min-height: 100%;
    float: none;
    height: auto;
    margin: 0 0.5rem;
  }
}
@media (max-width: 991px) {
  .featured-products .products {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 22px;
  }
}
