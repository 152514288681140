@import "_variables";
@import "_bootstrap-grid";
@import "_slick";
@import "_normalise";
@import "_typography";
@import "_mixins";
@import "_functions";
@import "_helpers";
// @import "_components";

::selection {
  background: $color--primary;
  color: #fff;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: $font--primary;
  font-size: 16px;
  font-weight: 500;
  color: $color--grey;
  overflow-x: hidden;
  scroll-behavior: smooth;
  letter-spacing: 0.2px;
}

// Reset container default width
.container {
  @include min_xl {
    max-width: 1230px;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font--secondary;
  font-weight: 800;
  text-transform: initial !important;
  color: $color--primary;
}

h1,
.h1 {
  font-size: 2rem;
}
h2,
.h2 {
  font-size: 1.5rem;
}

p {
  line-height: 1.5;
  color: $color--grey;
  letter-spacing: 0.3px;
  font-size: inherit;
}
.weight-3 {
  p {
    font-weight: 300;
  }
}
.mb-p {
  p {
    margin-bottom: 3px !important;
  }
}
