@import "_variables";

.heading {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-align: center;
  color: #000;
  width: max-content;
  max-width: 100%;
  text-transform: initial !important;
  padding-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 2.2rem !important;
  @include max_xs {
    font-size: 36px;
    margin-bottom: 1rem !important;
  }
  &::after {
    content: "";
    display: block;
    width: 75px;
    height: 2px;
    background: lighten($color--secondary, 25%);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.btn {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0.85rem 1rem;
  border: 2px solid currentColor;
  border-radius: 2px;
  box-shadow: none;
  // min-width: 180px;
  outline: none;
  &-primary {
    color: #fff;
    background-color: $color--secondary;
    border-color: $color--secondary;
    &:hover {
      color: #fff;
      background-color: $color--primary;
      border-color: $color--primary;
    }
  }
  &-secondary {
    border-color: #f2ecdc;
    background-color: #fff;
  }
  &-sm {
    padding: 10px 35px;
    border-radius: 0;
    font-size: 16px;
    font-weight: 700;
  }
  &-icon {
    padding-left: 45px;
    position: relative;
    font-weight: 300;
    i,
    .icon {
      position: absolute;
      left: 14px;
      width: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.card {
  color: darken($color--grey, 20%);
  &--bordered {
    box-shadow: none;
    border: 1px solid lighten($color--grey, 30%);
    // background-color: $color--skin;
  }
}
