$color--primary: #000;
$color--secondary: #b89332;
$color--grey: #6f6f6f;
$color--skin: #f1f4f8;
$color--dark: #272727;
$font--primary: "Roboto", sans-serif;
$font--secondary: "Playfair Display", sans-serif;
$shadow: 0 2px 9px 2px #ededed;

$transition: 0.2s;
