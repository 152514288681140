#header {
  color: $color--primary;

  &.is-open {
    .header {
      box-shadow: none;
    }
    .header__mobile__wrapper {
      .header__search {
        display: none;
      }
    }
  }
  .header {
    box-shadow: 0 2px 9px 2px lighten($color--grey, 32%);
    &__banner {
      font-size: 14px;
    }
    &__search {
      .search-widget input[type="text"] {
        font-size: 16px;
        padding-left: 0;
        border: 0;
        border-bottom: 1px solid lighten($color--grey, 20%);
        padding-left: 6px;
        &:focus,
        &:active {
          border-width: 0 0 1px 0;
          border-color: $color--secondary;
          outline: none;
        }
        &::placeholder {
          color: #000 !important;
          opacity: 1;
          font-weight: 700;
        }
      }
      .material-icons {
        font-size: 31px;
        color: #000;
      }
    }
    &__logo {
      width: 100%;
      max-width: 300px;
    }
    &__top {
      &__grid {
        gap: 1rem;
        @include max_md {
          display: none !important;
        }
        & > * {
          flex: 1;
        }
      }
      &__menu {
        justify-content: flex-end;
        gap: 20px;
        font-size: 16px;
        font-weight: 600;
        &__inner {
          display: flex;
          align-items: center;
          gap: 1rem;
        }
        .user-info {
          display: flex;
          & > a {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
    // Block cart
    .blockcart {
      position: relative;
      .cart-products-count {
        font-size: 12px;
        font-weight: 500;
        padding: 5px;
        line-height: 1;
        height: 22px;
        width: 22px;
        position: absolute;
        top: 0;
        right: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color--secondary;
        color: #fff;
        border-radius: 100%;
        transform: translateX(50%) translateY(-4px);
      }
    }

    &__nav {
      @include max_md {
        display: none !important;
      }
      .menu {
        flex: 1;
      }
      .top-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 24px;

        li {
          a {
            font-family: "Lora";
            // font-family: $font--secondary;
            font-size: 14px;
            font-weight: 700;
            padding: 14px 14px;
            text-transform: uppercase;
          }
        }
      }
      .clearfix {
        display: none;
      }
    }
    &__mobile {
      &__wrapper {
        @include min_md {
          display: none !important;
        }
        .header__search {
          border-top: 1px solid #d4d4d4;
          padding: 0.5rem 1rem;
          .is-open & {
            display: none;
          }
          .search-widget {
            display: block;
            input[type="text"] {
              width: 100%;
              border: 0;
              &::placeholder {
                color: $color--primary !important;
                opacity: 1;
              }
            }
          }
        }
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      padding: 1rem 0;
      & > * {
        flex: 1;
      }
      &__left {
        i {
          font-size: 44px;
        }
      }
      &__center {
        .top-logo {
          width: 200px;
          max-width: 100%;
        }
      }
      &__right {
        gap: 0.5rem;
        .icon {
          width: 26px;
        }
        // .blockcart {
        //   .cart-products-count {
        //   }
        // }
      }
    }
  }
  .navbar-toggler {
    text-align: right;
    i {
      margin-left: auto;
      margin-right: -5px;
    }
  }
  .language-selector,
  .currency-selector {
    flex-shrink: 0;
    display: block !important;
    button {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      flex-shrink: 0;
      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        font-size: 25px;
        line-height: 1;
        width: 32px;
        height: 32px;
        padding: 4px;
        color: #fff;
        background-color: $color--primary;
        border-radius: 100%;
        &.lang {
          font-family: "Lora";
          font-size: 14px;
          font-weight: 700;
        }
      }
      .expand-more {
        transform: rotate(90deg);
        width: 12px;
        color: $color--dark;
        margin-top: 5px;
      }
    }
    .dropdown-menu {
      min-width: inherit;
      background-color: $color--primary;
      border-radius: 4px;
      left: -10px;
      min-width: 100%;
      @include max_md {
        left: 0;
      }
      .dropdown-item {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.4px;
        color: #fff;
        padding: 5px 10px;
        &:hover {
          background-color: #fff;
          color: $color--primary;
        }
      }
    }
  }
}

.ui-menu {
  font-family: $font--primary;
  padding: 0.5rem 0;
  border: 1px solid $color--primary;
  box-shadow: $shadow;
  &-item a {
    font-size: 14px;
    padding: 4px 1rem !important;
    border-radius: 0;
    &.ui-state-focus {
      background: $color--primary;
      color: #fff;
      border-color: $color--primary;
    }
  }
}
.eur {
  padding-right: 7px !important;
}
