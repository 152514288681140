.reassurance {
  background: $color--skin;
  &__inner {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-content: center;
    gap: 2rem;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 0.75rem;
    &__title {
      font-size: 18px;
      font-weight: 500;
      color: $color--secondary;
      small {
        display: block;
        color: $color--primary;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
body#checkout #footer {
  box-shadow: none;
  background: $color--primary;
  color: $color--grey;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.4px;
  text-align: center;
}
.footer {
  // Resets
  #block_myaccount_infos .myaccount-title a,
  .block-contact .block-contact-title,
  .linklist .blockcms-title a {
    color: #fff;
  }
  &-container {
    padding: 2.5rem 0;
    margin: 0;
    @include max_md {
      padding: 0.5rem 0;
    }
  }
  &__top {
  }
  &__bottom {
    background-color: #000;
    & a,
    & p,
    & * {
      color: #8c8c8c;
    }
    .h3,
    .h4 {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase !important;
      letter-spacing: 0.4px;
      padding-bottom: 0.5rem;
      a {
        display: block;
      }
    }
    .links {
      .title {
        padding: 0.75rem 1rem;
      }
      ul {
        @include max_md {
          padding: 0 1rem;
          background-color: darken($color--dark, 5%);
          li {
            border-bottom: 1px solid darken($color--grey, 20%);
            margin: 0;
            color: #fff;
            font-weight: 400;
            &:last-child {
              border-bottom: none;
            }
            a {
              color: #fff !important;
            }
          }
        }
        li {
          &:last-child {
            margin-bottom: 0;
          }
          a {
            font-size: 14px;
            font-weight: 300;
            img {
              display: none;
            }
          }
        }
      }
    }
  }
  &__payment {
    display: flex;
    align-items: flex-end;
    gap: 1rem;
  }
  .block-contact {
    font-size: 14px;
    font-weight: 400;
  }
  .block-social {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ul {
      gap: 1rem;
      justify-content: flex-end;
      @include max_xs {
        justify-content: flex-start;
      }
      li {
        height: 2.5rem;
        width: 2.5rem;
        background-repeat: no-repeat;
        margin: 0;
        cursor: pointer;
        border-radius: 100%;
        background-size: 30px;
        background-position: center;
        background-color: $color--secondary;
        border: 2px solid $color--secondary;
        &:hover {
          background-color: $color--primary;
          border-color: #fff;
        }
        // a {
        // }
      }
    }
  }
  .navbar-toggler {
    text-align: right;
    i {
      color: $color--secondary;
    }
  }
}
