#wrapper {
  background: none;
  box-shadow: none;
  // padding: 3rem 0;
  padding: 0;
  overflow-x: hidden;
  .breadcrumb {
    margin-bottom: 2rem;
    li {
      a {
        &:hover {
          text-decoration: underline;
        }
      }
      a,
      span {
        font-size: 14px;
      }
    }
  }
}
.page-header {
  .h1,
  h1 {
    @extend .heading;
  }
}

// Forms
.form-control-label {
  text-align: left;
}
.form-control-comment {
  font-size: 12px;
  letter-spacing: 0.4px;
  padding-top: 0.3rem;
  color: $color--grey;
  display: block;
}
// cards
.card {
  box-shadow: none;
  border: 1px solid lighten($color--grey, 20%);
}
.cart-items {
  @include max_sm {
    margin: -1rem 0;
  }
}
.cart-item,
.separator {
  border-color: lighten($color--grey, 20%);
}
.cart-grid-body .card-block h1 {
  line-height: 1;
  font-weight: 800;
}
.cart-grid-right {
  .checkout {
    .btn {
      width: 100%;
    }
  }
}
.cart-line-product-actions {
  line-height: 1.2 !important;
}
// account
.page-my-account {
  #content .links a span.link-item {
    box-shadow: none;
    border: 2px solid lighten($color--grey, 30%);
    background-color: $color--skin;
  }
  .account-logout {
    color: rgb(209, 68, 68);
    &:hover {
      text-decoration: underline;
    }
    .icon {
      width: 40px;
    }
  }
}
.table {
  .order-actions {
    display: flex;
    gap: 10px;
    a {
      background-color: $color--primary;
      color: #fff;
      border-radius: 6px;
      padding: 5px 8px !important;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
  }
}

.page-order-detail {
  .box {
    box-shadow: none;
    border: 1px solid #cecece;
  }
}
.addresses-footer {
  margin-bottom: 1rem !important;
}

.page-customer-account #content {
  box-shadow: none;
  border: 2px solid lighten($color--grey, 30%);
  background-color: $color--skin;
}
// Chekout
body#checkout section.checkout-step {
  border-bottom-width: 1px;
  margin-bottom: -1px;
}
body#checkout section.checkout-step .address-alias {
  transform: translateY(3px);
}
body#checkout section.checkout-step:last-child {
  border-width: 1px;
}
.page-addresses .address,
body#checkout section.checkout-step,
.page-authentication #content {
  @extend .card--bordered;
}
body#checkout section.checkout-step {
  .step-title {
    font-family: $font--primary;
  }
  .address-item {
    header {
      font-family: $font--primary;
    }
    &.selected {
      border: 2px solid $color--secondary;
    }
  }
  .radio-block {
    display: block;
    margin: 0;
  }
  .address-alias {
    font-family: $font--primary;
    margin: 0;
    margin-left: 5px;
    display: inline-block;
  }
  .address-footer {
    display: flex;
    justify-content: space-between;
  }
  .address {
    padding: 15px 0 0;
    margin: 0;
    line-height: 1.4;
    font-weight: 500;
  }
  .content {
    padding: 1.5rem !important;
    background: #ecedef;
    border-radius: 4px;
    @include max_xs {
      padding: 1rem !important;
    }
    a {
      text-decoration: underline;
    }
  }
  #payment-confirmation {
    margin-top: 1rem;
  }
  .btn {
    &.continue {
      @include max_xs {
        width: 100%;
      }
    }
  }
}
#order-items {
  border: none;
  .card-title {
    font-size: 1.3rem;
    font-family: $font--primary;
  }
  .order-line {
    margin-top: 0;
    padding-top: 1rem;
    &:nth-child(2n) {
      background: $color--skin;
    }
  }
  .order-confirmation-table {
    margin-top: 0.5rem;
  }
  .qty {
    .row {
      gap: 5px;
      margin: 0;
      &::after {
        display: none;
      }
      & > * {
        flex: 1;
        max-width: inherit;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 5px;
        background: $color--skin;
        border: 1px solid lighten($color--grey, 20%);
      }
    }
  }
}

.product-flags li.product-flag {
  font-size: 14px;
  font-weight: 500 !important;
  text-transform: capitalize;
}

// Cart
.page-addresses .address {
  .address-body {
    h4 {
      font-size: 1.4rem;
      font-weight: 800;
    }
  }
  .address-footer {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid lighten($color--grey, 30%);
  }
}

// GPDR
.psgdprinfo17 {
  margin: 0;
  padding: 1.5rem 0;
  &:not(:last-child) {
    border-bottom: 1px solid lighten($color--grey, 10%);
  }
  .btn {
    font-size: 12px;
    padding: 5px 10px;
    margin: 0.4rem;
  }
}

// Modal

#blockcart-modal .modal,
.modal {
  &-title {
    font-size: 1.6rem;
    font-weight: 800;
    color: $color--primary;
    display: flex;
    align-items: center;
    line-height: 1.2;
    @include max_xs {
      font-size: 1.4rem;
    }
  }
  &-header {
  }
  .cart-content-btn {
    @include max_xs {
      display: flex !important;
      flex-direction: column;
      .btn {
        width: 100%;
        i {
          display: none;
        }
      }
    }
  }
  .image-caption {
    background-color: $color--primary !important;
    border-color: $color--primary !important;
    color: #fff;
    p {
      font-size: 16px;
      font-size: 14px;
      color: #fff;
      text-align: center;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
  }
}

.input-group.bootstrap-filestyle .btn {
  padding: 0.78rem 1rem;
  margin-left: -1px;
}

.contact-form {
  .form-footer {
    .btn {
      @include max_xs {
        width: 100%;
      }
    }
  }
}
#content-wrapper,
#left-column {
  margin-bottom: 1rem;
}
// #content-wrapper,
// #left-column {
// 	padding-left: 0 !important;
// 	padding-right: 0 !important;
// }

#fpg-widget-wrapper .fpg_product {
  border: 3px solid $color--primary;
  .fpg_select,
  .fpg_info {
    height: 35px;
    width: 35px;
    border-radius: 100%;
    background-color: $color--primary;
    i,
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      // &.added::after {
      // 	content: "\e5ca";
      // 	font-family: "Material Icons";
      // 	font-weight: 400;
      // 	font-size: 24px;
      // 	font-style: normal;
      // }
    }
  }
  &.selected {
    border: 3px solid $color--secondary;
    .fpg_select,
    .fpg_info {
      background: $color--secondary !important;
      color: #fff;
    }
    .fpg_select {
      // span,
      i {
        display: flex;
        height: 100%;
        &.added::after {
          content: "\e5ca";
          font-family: "Material Icons";
          font-weight: 400;
          font-size: 24px;
          font-style: normal;
        }
      }
    }
  }
}

.page-footer {
  margin-top: 1.5rem;
}
