* {
	margin: 0;
	padding: 0;
}
ul {
	margin: 0;
	padding: 0;
}
li {
	list-style-type: none;
}
a {
	text-decoration: none;
	color: inherit;
}
a:focus,
button:focus {
	outline: -webkit-focus-ring-color auto 1px;
}
button {
	background: none;
	outline: 0;
	border: none;
	cursor: pointer;
}
button,
input,
select {
	font-family: inherit;
}
svg {
	max-width: 100%;
	fill: currentColor;
}
img {
	display: block;
}
.img-fluid {
	max-width: 100%;
	height: auto;
}
p:empty {
	margin: 0;
	height: 0;
	display: none;
}
